import * as React from 'react'
import Layout from '../components/layout'
import { SEO } from "../components/seo"

const bookingPage = () => {
  return (
    <Layout pageTitle="Booking page">
        <iframe src="https://ventress-group-llc.aryeo.com/order" width="100%" height="800" frameBorder="0"></iframe>
    </Layout>
  )
}

export const Head = () => (
  <SEO title="Booking page" />
)

export default bookingPage


